let CalculatorRevenueIntegrity = (function($) {

    // User inputs, model constraints, etc...
    let inputs = {
            gpr: 40000000000,
            npr: 10000000000,
            per_ip: 0.54,
            per_com: 0.25,
            per_gov: 0.65,
            per_self: 0.1,
        },
        constraints = {
            ci_upper:  0.00275,
            ci_lower:  0.0007,
            drg_upper: 0.00215,
            drg_lower: 0.00045,
        },
        calculated, rates, custom, outputs;

    // Input elements
    let $gpr, $npr, $per_ip, $per_com, $per_gov, $per_self;

    // Label elements
    let $gpr_label, $npr_label, $per_ip_label, $per_com_label,
        $per_gov_label, $per_self_label, $per_100_label;

    // Output elements
    let $pure_lower, $pure_upper, $range_lower, $range_upper;

    /**
     * Initialize calculator...
     */
    function init() {
        if(! $('#gpr').length) { return }

        $('.user-inputs').each(function(){
            $(this).keyup(debounce(calculate, 500));
        });

        // input elements
        $gpr = new Cleave('#gpr', {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand'
        });
        $npr = new Cleave('#npr', {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand'
        });
        $per_ip   = $('#per_ip');
        $per_com  = $('#per_com');
        $per_gov  = $('#per_gov');
        $per_self = $('#per_self');

        // Labels
        $gpr_label      = $('#gpr_label');
        $npr_label      = $('#npr_label');
        $per_ip_label   = $('#per_ip_label');
        $per_com_label  = $('#per_com_label');
        $per_gov_label  = $('#per_gov_label');
        $per_self_label = $('#per_self_label');
        $per_100_label  = $('#per_100_label');

        // Calculated elements
        $pure_lower  = $('#pure_lower');
        $pure_upper  = $('#pure_upper');
        $range_lower = $('#range_lower');
        $range_upper = $('#range_upper');

        // Text Inputs
        $gpr.setRawValue(inputs.gpr);
        $npr.setRawValue(inputs.npr);

        // Commercial Slider
        $per_ip.slider({
            range: "min",
            min: 0,
            max: 100,
            value: inputs.per_com * 100,
            step: 1,
            slide: function (event, ui) {
                inputs.per_ip = ui.value;
                $per_ip_label.text(ui.value + '%');
                $per_ip.slider('value', ui.value);
                calculate();
            }
        });

        // Commercial Slider
        $per_com.slider({
            range: "min",
            min: 0,
            max: 100,
            value: inputs.per_com * 100,
            step: 1,
            slide: function (event, ui) {
                if(ui.value + $per_self.slider('value') + $per_gov.slider('value') <= 100) {
                    inputs.per_com = ui.value;
                    $per_com_label.text(ui.value + '%');
                    $per_com.slider('value', ui.value);
                    $per_100_label.text(ui.value + $per_self.slider('value') + $per_gov.slider('value'));
                    calculate();
                } else {
                    inputs.per_com = 100 - ($per_self.slider('value') + $per_gov.slider('value'));
                    $per_com_label.text(100 - ($per_self.slider('value') + $per_gov.slider('value')) + '%');
                    $per_com.slider('value', 100 - ($per_self.slider('value') + $per_gov.slider('value')));
                    $per_100_label.text(100);
                    calculate();
                    return false;
                }
            }
        });


        // Government Slider
        $per_gov.slider({
            range: "min",
            min: 0,
            max: 100,
            value: inputs.per_gov * 100,
            step: 1,
            slide: function (event, ui) {
                if(ui.value + $per_com.slider('value') + $per_self.slider('value') <= 100) {
                    inputs.per_gov = ui.value;
                    $per_gov_label.text(ui.value + '%');
                    $per_gov.slider('value', ui.value);
                    $per_100_label.text(ui.value + $per_com.slider('value') + $per_self.slider('value'));
                    calculate();
                } else {
                    inputs.per_gov = 100 - ($per_com.slider('value') + $per_self.slider('value'));
                    $per_gov_label.text(100 - ($per_com.slider('value') + $per_self.slider('value')) + '%');
                    $per_gov.slider('value', 100 - ($per_com.slider('value') + $per_self.slider('value')));
                    $per_100_label.text(100);
                    calculate();
                    return false;
                }
            }
        });


        // Self Pay Slider
        $per_self.slider({
            range: "min",
            min: 0,
            max: 100,
            value: inputs.per_self * 100,
            step: 1,
            slide: function (event, ui) {
                if(ui.value + $per_com.slider('value') + $per_gov.slider('value') <= 100) {
                    inputs.per_self = ui.value;
                    $per_self_label.text(ui.value + '%');
                    $per_self.slider('value', ui.value);
                    $per_100_label.text(ui.value + $per_com.slider('value') + $per_gov.slider('value'));
                    calculate();
                } else {
                    inputs.per_self = 100 - ($per_com.slider('value') + $per_gov.slider('value'));
                    $per_self_label.text(100 - ($per_com.slider('value') + $per_gov.slider('value')) + '%');
                    $per_self.slider('value', 100 - ($per_com.slider('value') + $per_gov.slider('value')));
                    $per_100_label.text(100);
                    calculate();
                    return false;
                }
            }
        });

        // Run initial calculation
        calculate();
    }

    /**
     * Calculate the values...
     * @param event
     * @param ui
     */
    function calculate(event, ui) {

        // GPR/NPR Validation
        if (inputs.gpr < 1000000000000) {
            $gprLastValue = inputs.gpr;
        }
        if (inputs.npr < inputs.gpr) {
            $nprLastValue = inputs.npr;
        }

        // Set user input values...
        inputs.gpr      = parseInt($gpr.getRawValue(), 10);
        inputs.npr      = parseInt($npr.getRawValue(), 10);
        inputs.per_ip   = $per_ip.slider('value')    / 100;
        inputs.per_com  = $per_com.slider('value')   / 100;
        inputs.per_gov  = $per_gov.slider('value')   / 100;
        inputs.per_self = $per_self.slider('value')  / 100;

        // GPR/NPR Validation
        if (isNaN(inputs.gpr)) {
            $gpr.setRawValue($gprLastValue);
            calculate();
        }
        if (isNaN(inputs.npr)) {
            $npr.setRawValue($nprLastValue);
            calculate();
        }

        // Return to last valid value if...
        if (inputs.gpr > 99999999999) {
            $gpr.setRawValue($gprLastValue);
            calculate();
        }
        if (inputs.npr > inputs.gpr) {
            $npr.setRawValue(inputs.gpr);
            calculate();
        }

        // Set range input labels...
        // $gpr_label.text('$' + $gpr.getFormattedValue());
        $npr_label.text('$' + $npr.getFormattedValue());
        $per_ip_label.text($per_ip.slider('value')     + '%');
        $per_com_label.text($per_com.slider('value')   + '%');
        $per_gov_label.text($per_gov.slider('value')   + '%');
        $per_self_label.text($per_self.slider('value') + '%');

        // Set simple calculations for output...
        calculated = {
            ci_upper_dollars:  inputs.npr * constraints.ci_upper,
            ci_lower_dollars:  inputs.npr * constraints.ci_lower,
            drg_upper_dollars: inputs.npr * constraints.drg_upper,
            drg_lower_dollars: inputs.npr * constraints.drg_lower,
        };

        // Set rates for custom calculation...
        rates = {
            ci_upper_rate:  calculated.ci_upper_dollars / inputs.npr,
            ci_lower_rate:  calculated.ci_lower_dollars / inputs.npr,
            drg_upper_rate: calculated.drg_upper_dollars / inputs.npr,
            drg_lower_rate: calculated.drg_lower_dollars / inputs.npr,
        };

        // Set custom calculations...
        custom = {
            ci_upper:  ((1 - (((inputs.per_self) + (inputs.per_gov)) * 0.66)) * rates.ci_upper_rate) * inputs.npr,
            ci_lower:  ((rates.ci_lower_rate * inputs.per_com) + rates.ci_lower_rate) * inputs.npr,
            drg_upper: ((1 - ((1 - inputs.per_ip) * 0.66)) * rates.drg_upper_rate) * inputs.npr,
            drg_lower: (((inputs.per_gov * inputs.per_ip * (1.625)) * rates.drg_lower_rate) + rates.drg_lower_rate) * inputs.npr,
            cash_factor: inputs.npr / inputs.gpr,
        };

        // A / (1 + exp(-k * (x - x0) )
        let impact = 0.125 / (1 + Math.exp(-0.5 * (custom.cash_factor - 0.1))),
            upper_sum = custom.ci_upper + custom.drg_upper;

        // Calculate raw outputs...
        outputs = {
            pure_lower:  calculated.ci_lower_dollars + calculated.drg_lower_dollars,
            pure_upper:  calculated.ci_upper_dollars + calculated.drg_upper_dollars,
            range_lower: custom.ci_lower + custom.drg_lower,
            range_upper: upper_sum + (upper_sum * impact),
        };

        // Set output field values...
        $pure_lower.text('$' + abbreviateNumber(outputs.pure_lower));
        $pure_upper.text('$' + abbreviateNumber(outputs.pure_upper));
        $range_lower.text('$' + abbreviateNumber(outputs.range_lower));
        $range_upper.text('$' + abbreviateNumber(outputs.range_upper));

        // Set output slider positions...
        let total_width = outputs.pure_upper - outputs.pure_lower,
            lower_width = (outputs.range_lower - outputs.pure_lower) / total_width * 100,
            upper_width = (outputs.range_upper - outputs.pure_lower) / total_width * 100;

        $('.slider-range-1').css('width', lower_width + '%');
        $('.slider-range-2').css('width', (100 - upper_width) + '%');
    }

    /**
     * Abbreviate the number...
     * @param num
     * @param fixed
     * @returns {string|null}
     */
    function abbreviateNumber(num, fixed) {
        if (num === null) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show

        let b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return e;
    }

    // Prevent provided function from running too frequently...
    //http://davidwalsh.name/javascript-debounce-function
    function debounce(func, wait, immediate) {
        let timeout;

        return function() {
            let context = this, args = arguments;
            let later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    return {
        init: init
    }

})(jQuery);

jQuery(document).ready(function() {
    CalculatorRevenueIntegrity.init();
});



